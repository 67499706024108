<template>
  <div class="dashboard">
    <b-loading
      v-if="isLoading"
      v-model="isLoading"
      :is-full-page="false">
      <!-- <lottie-player
        src="https://lottie.host/c82b688b-d3d2-463b-8ef7-16cb0b169ba3/FwKwjZGZTa.json"
        background="transparent"
        speed="1"
        style="width: 70px"
        autoplay
        loop /> -->
    </b-loading>

    <div
      v-else
      class="dashboard-container"
      :class="{
        container: isContained,
      }">
      <MobileDashboardHeader
        v-if="$screen.is('sm')"
        v-model="dataContext"
        :is-contained="isContained"
        class="dashboard-header"
        :dashboard-details="dashboardDetails"
        @toggleContainer="toggleContainer" />

      <DashboardHeader
        v-else
        v-model="dataContext"
        :is-contained="isContained"
        class="dashboard-header"
        :dashboard-details="dashboardDetails"
        @toggleContainer="toggleContainer" />

      <div class="dashboard-content">
        <DashboardTabs
          v-model="selectedTab"
          :tabs="tabOptions" />
        <div
          v-for="section in dashboardDetails.dashboard_sections"
          :key="section.id">
          <DashboardContent
            v-if="section.id === selectedTab"
            :dashboard-data-context="dataContext"
            :dashboard-key="dashboardKey"
            :dashboard-section="section" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DashboardHeader from './components/DashboardHeader.vue'
  import MobileDashboardHeader from './components/MobileDashboardHeader.vue'
  import DashboardTabs from './components/DashboardTabs.vue'
  import DashboardContent from './components/DashboardContent.vue'

  export default {
    components: {
      DashboardHeader,
      MobileDashboardHeader,
      DashboardTabs,
      DashboardContent,
    },
    provide () {
      return {
        isContained: () => this.isContained,
      }
    },
    data () {
      return {
        isContained: true,
        isLoading: true,
        selectedTab: 'Summary', // Default to 'Summary

        dashboardDetails: null,
        dashboardKey: null,
        dataContext: {
          date_selection: {
            selected_dates: [],
          },
          filter_selections: {},

          control_selections: {},
        },
      }
    },
    computed: {
      tabOptions () {
        return (
          this.dashboardDetails?.dashboard_sections?.map((section) => ({
            id: section?.id,
            label: section?.name,
            icon: section?.icon,
          })) || []
        )
      },
    },
    mounted () {
      this.dashboardKey = this.$route.params.id
      this.loadDashboard(this.dashboardKey)
    },
    methods: {
      toggleContainer () {
        this.isContained = !this.isContained
      },
      async loadDashboard (dashboardKey) {
        this.isLoading = true
        try {
          const dashboardDetails =
            await this.$apis.companyConfigs.getDashboardReportDetails(
              dashboardKey
            )
          this.dashboardDetails = dashboardDetails
          this.selectedTab = this.dashboardDetails?.dashboard_sections[0]?.id
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.dashboard {
  padding: 16px;
  background-color: #f2f2f2;

  @include mobile {
    padding: 0px;
  }
}

.dashboard-container {
  padding: 0 !important;
}
.dashboard-header {
  margin-bottom: 32px;
  margin-top: 16px;

  @include mobile {
    margin: 0;
  }
}

.dashboard-content {
  border: 1px solid #e0e0e0;
  background-color: #fbfbfb;
  border-radius: 4px;
  overflow-x: hidden;
}
</style>
