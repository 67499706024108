<template>
  <div
    ref="barChart"
    class="bar-chart-container" />
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'BarChart',
    inject: {
      isContained: {
        default: () => true,
      },
    },
    props: {
      data: {
        type: Array,
        required: true,
      },
      yAxisDataType: {
        type: String,
        required: true,
      },
      measureLabel: {
        type: String,
        default: '',
      },
      benchmarkLabel: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      isContainerExpanded () {
        return this.isContained()
      },
      chartOptions () {
        return {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              snap: true,
            },
            backgroundColor: '#28272A',
            borderColor: '#333333',
            textStyle: {
              color: '#FFFFFF',
              fontSize: 12,
            },
            formatter: (params) => {
              let result = `<div style="font-size:12px;text-transform:capitalize">${params[0].name}</div>`

              params.forEach((param) => {
                const value = param.data.formatted || param.value
                result += `<div style="display:flex;justify-content:space-between;gap:8px;margin-top:4px">
                <span>${param.marker}${param.seriesName}:</span>
                <span style="">${value}</span>
              </div>`
              })

              result +=
                '<div style="font-size:12px;color:#888;margin-top:8px;border-top:1px solid #555; padding-top: 10px;">Click to drill down</div>'
              return result
            },
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            top: '3%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: this.data.map((item) => item.label),
            axisLabel: {
              interval: 0,
              rotate: 45,
              margin: 15,
              color: '#7E7D84',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              color: '#B2B1B6',
              formatter: (value) => {
                if (value === 0) return ''
                if (this.yAxisDataType === 'money') {
                  return '$' + (value / 100).toLocaleString()
                }
                if (this.yAxisDataType === 'percentage') {
                  return (value * 100).toFixed(2) + '%'
                }
                return value
              },
            },
          },
          series: [
            {
              name: this.measureLabel,
              type: 'bar',
              data: this.data.map((item) => ({
                value: item.value,
                formatted: item.formatted_value,
                itemStyle: {
                  color: item.color,
                },
              })),
              barMaxWidth: '50%',
              itemStyle: {
                borderRadius: [3, 3, 0, 0,],
              },
              z: 2,
              silent: true,
            },
            {
              name: this.benchmarkLabel,
              type: 'bar',
              data: this.data.map((item) => ({
                value: item.benchmark,
                formatted: item.formatted_benchmark,
                itemStyle: {
                  color: item.color + '33',
                },
              })),
              barMaxWidth: '50%',
              barGap: '-40%', // Makes the benchmark bar overlap with main bar
              itemStyle: {
                borderRadius: [5, 5, 0, 0,],
              },
              z: 1,
              silent: true,
            },
          ],
        }
      },
    },
    watch: {
      data: {
        handler () {
          this.updateChart()
        },
        deep: true,
      },
      isContainerExpanded: {
        handler () {
          this.$nextTick(() => {
            this.resizeChart()
          })
        },
      },
    },
    mounted () {
      this.initChart()
      window.addEventListener('resize', this.resizeChart)
    },
    beforeDestroy () {
      if (this.chartInstance) {
        this.chartInstance.dispose()
      }
      window.removeEventListener('resize', this.resizeChart)
    },
    methods: {
      initChart () {
        this.chartInstance = echarts.init(this.$refs.barChart)
        this.chartInstance.setOption(this.chartOptions)

        // Add click handler for the entire chart area
        this.$refs.barChart.addEventListener('click', (event) => {
          // Get mouse coordinates relative to chart container
          const rect = this.$refs.barChart.getBoundingClientRect()
          const x = event.clientX - rect.left
          const y = event.clientY - rect.top

          // Convert pixel coordinates to data coordinates
          const pointInPixel = [x, y,]
          const pointInGrid = this.chartInstance.convertFromPixel(
            { seriesIndex: 0, },
            pointInPixel
          )

          if (pointInGrid) {
            // Find closest data point
            const dataIndex = Math.round(pointInGrid[0])
            if (dataIndex >= 0 && dataIndex < this.data.length) {
              const item = this.data[dataIndex]
              this.$emit('onDrilldown', {
                key: item.id,
                label: item.label,
              })
            }
          }
        })
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.chartOptions)
        }
      },
      resizeChart () {
        if (this.chartInstance) {
          this.chartInstance.resize()
        }
      },
    },
  }
</script>

<style>
.bar-chart-container {
  width: 100%;
  height: 100%;
}
</style>
