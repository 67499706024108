<template>
  <button
    class="button is-small export-button"
    :class="{ 'is-loading disabled': isExporting }"
    @click="exportCsv">
    Download CSV
  </button>
</template>
<script>

  export default {
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        isExporting: false,
      }},

    methods: {

      async exportCsv () {
        this.isExporting = true

        const response = await this.$apis.companyConfigs.getDashboardModuleCsv(
          this.dashboardKey,
          this.dashboardModule.key,
          this.dashboardDataContext
        )

        const blob = new Blob([response,], { type: 'text/csv', })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = 'dashboard_data.csv'
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)

        this.isExporting = false
      },
    },
  }
</script>
<style scoped lang="scss">
.export-button {
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
}
</style>

