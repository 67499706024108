<template>
  <div class="location-filter">
    <div class="location-icon">
      <img
        v-if="selectionModel?.logo_fname"
        :src="logoUrl"
        alt="Location Icon"
        class="icon" />
    </div>
    <div class="right">
      <div class="quick-actions">
        <div class="button-label">
          {{ control.name }}
        </div>
        <!-- <button class="button remove-button">
          <i class="material-icons icon">close</i>
        </button> -->
      </div>
      <div class="dropdown-filter">
        <singleSelect
          v-model="selectionModel"
          track-by="key"
          :no-border="true"
          label="label"
          :disabled="loading"
          :loading="loading"
          :options="dropdownOptions"
          @input="updateSelectionModel" />
      </div>
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'LocationFilter',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterKey: {
        type: String,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      control () {
        return {
          name: 'Location',
        }
      },
      logoUrl () {
        return `${process.env.VUE_APP_S3_BASE_URL}/${this.selectionModel?.logo_fname}`
      },
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions =
        await this.$apis.companyConfigs.getDashboardFilterOptions(
          this.dashboardKey,
          this.filterKey,
          this.dataContext
        )

      this.selectionModel = this.dropdownOptions[0]
      this.updateSelectionModel(this.dropdownOptions[0])
    },

    methods: {
      updateSelectionModel (value) {
        this.$set(this.dataContext.filter_selections, this.filterKey, [
          value.key,
        ])
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .dropdown-menu {
  padding: 0 !important;
}

.location-filter {
  background-color: white;
  border-radius: 4px;
  display: flex;
  width: 100%;
}
.right {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 1px solid $gray-1;
}
.location-icon {
  padding: 8px;
  display: flex;
  align-items: center;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 50px;
    height: 50px;
  }
}
.filter-button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  width: 100%;
  border: none !important;
  cursor: pointer;

  &.filtered {
    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-1;
  // border-bottom: none;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
