<template>
  <div class="optional-filters">
    <div
      v-if="initialized"
      class="columns filter is-multiline">
      <div
        v-for="filter in optionalFilters"
        :key="filter.id"
        class="column is-3">
        <CustomFilter
          v-model="localDataContext[filter.key]"
          :filter-meta="filter"
          :fulldata-context="value"
          :dashboard-key="dashboardKey"
          @input="makeInputsDirty" />
      </div>
    </div>
    <div class="actions">
      <button
        class="button mr-3 reset-button"
        :class="{ 'is-disabled': !isResetDirty }"
        :disabled="!isResetDirty"
        @click="removeAllFilters">
        Reset
      </button>
      <button
        class="button update-button"
        :class="{ 'is-disabled': !isUpdateDirty }"
        :disabled="!isUpdateDirty"
        @click="updateDataContext">
        Update
      </button>
    </div>
  </div>
</template>

<script>
  import CustomFilter from './CustomFilter.vue'

  export default {
    name: 'DashboardOptionalFilters',
    components: {
      CustomFilter,
    },
    props: {
      optionalFilters: {
        type: Array,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        localDataContext: {},
        initialized: false,
        isResetDirty: false,
        isUpdateDirty: false,
      }
    },
    mounted () {
      this.optionalFilters.forEach((filter) => {
        this.$set(this.localDataContext, filter.key, [])
      })
      this.initialized = true
    },
    methods: {
      makeInputsDirty () {
        this.isUpdateDirty = true
      },
      updateDataContext () {
        //removes all the other properties in and converts to array of keys
        const formattedLocalDataContext = Object.entries(
          this.localDataContext
        ).reduce((acc, [key, values,]) => {
          if (values != null) {
            // skip filters without any values
            acc[key] = values.map((value) => value.key)
          }
          return acc
        }, {})

        // Check if current filter selections are the same as new ones
        const currentSelections = this.value.filter_selections || {}
        const optionalKeys = this.optionalFilters.map((filter) => filter.key)
        const currentKeys = Object.keys(currentSelections)
        const newKeys = Object.keys(formattedLocalDataContext)
        const requiredSelections = {}
        Object.keys(this.value.filter_selections).filter((filter) => !optionalKeys.includes(filter.key)).forEach(
          (requiredKey) => {
            requiredSelections[requiredKey] = this.value.filter_selections[requiredKey]
          }
        )

        // Return early if keys and values are identical,
        // to prevent unnecessary updates down the line as all the module react to changes.
        if (
          currentKeys.length === newKeys.length &&
          currentKeys.every(
            (key) =>
              JSON.stringify(currentSelections[key]) ===
              JSON.stringify(formattedLocalDataContext[key])
          )
        ) {
          return
        }

        this.$emit('input', {
          ...this.value,
          filter_selections: {
            ...formattedLocalDataContext,
            ...requiredSelections,
          },
        })

        if (Object.keys(formattedLocalDataContext).length === 0) {
          this.isResetDirty = false
        } else {
          this.isResetDirty = true
        }

        this.isUpdateDirty = false
      },
      removeAllFilters () {
        Object.keys(this.localDataContext).forEach((key) => {
          this.$set(this.localDataContext, key, null)
        })
        this.updateDataContext()
      },
    },
  }
</script>

<style scoped lang="scss">
.filter {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 0 !important;
  padding: 10px;
}

.actions {
  display: flex;
  align-items: center;
  border-top: 1px solid #d2d2d4;
  padding: 10px;
  justify-content: flex-end;
  width: 100%;

  @include mobile {
    flex-direction: row-reverse;
  }
}

.optional-filters {
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mobile {
    flex-direction: column-reverse;
  }
}

.actions button {
  width: 100px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s ease;

  &.is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @include mobile {
    width: 100%;
  }
}

.reset-button {
  border: 1px solid rgba(255, 119, 89, 0.25) !important;
  color: #ff6341 !important;
  background-color: rgba(255, 99, 65, 0.1) !important;

  &:hover:not(.is-disabled) {
    border: 1px solid rgba(255, 119, 89, 0.5) !important;
    background-color: rgba(255, 99, 65, 0.15) !important;
  }

  &.is-disabled {
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    color: #d2d2d4 !important;
  }
}

.update-button {
  background-color: #b654b1 !important;
  border-color: transparent !important;
  color: #fff !important;

  &:hover:not(.is-disabled) {
    background-color: #994095 !important;
  }

  &.is-disabled {
    background-color: #f5f5f5 !important;
    border-color: transparent !important;
    color: #d2d2d4 !important;
  }
}
</style>
