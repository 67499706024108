<template>
  <div class="table-module">
    <div class="table-header">
      {{ dashboardModule.name }}

      <div class="actions">
        <ExportButton
          :dashboard-module="dashboardModule"
          :dashboard-data-context="dashboardDataContext"
          :dashboard-key="dashboardKey" />
      </div>
    </div>
    <b-skeleton
      v-if="isLoading"
      height="400px"
      style="border-radius: 0"
      width="100%"
      :active="isLoading" />
    <div
      v-else
      class="table-container">
      <table-column
        v-for="(column, i) in data.columns"
        :key="column.id"
        :class="{ 'sticky-column': i === 0 }"
        :current-depth="0"
        :column="column"
        :table-sort.sync="sort" />
    </div>
  </div>
</template>

<script>
  import ExportButton from '../ExportButton.vue'

  import TableColumn from './TableColumn.vue'
  import axios from 'axios'
  import { orderBy } from 'lodash'
  export default {
    name: 'TableModule',
    components: {
      TableColumn, ExportButton,
    },

    provide () {
      return {
        getMaxDepth: () => this.maxDepth,
        getRows: () => this.rows,
      }
    },
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        data: {
          type: '',
          id: '',
          key: '',
          name: '',
          rows: [],
          columns: [],
          depth: 2,
        },
        sort: {},
        isLoading: false,
        currentRequest: null,
      }
    },
    computed: {
      maxDepth () {
        return this.data.depth
      },
      rows () {
        if (this.sort.rowIdx != undefined) {
          const sortedRows = orderBy(
            this.data.rows,
            [
              (row) => row.cells[this.sort.rowIdx].sort_by,
            ],
            [
              this.sort.ascending ? 'asc': 'desc',
            ]
          )
          const groupMins = []
          sortedRows.forEach((row) => {
            row.sort_groups.forEach((group, idx) => {
              if (idx > groupMins.length - 1) {
                groupMins.push({})
              }
              if (groupMins[idx][row.sort_groups.slice(0,idx + 1).join('-')] == undefined) {
                groupMins[idx][row.sort_groups.slice(0,idx + 1).join('-')] = row.cells[this.sort.rowIdx].sort_by
              }
            })
          })
          return orderBy(
            this.data.rows,
            groupMins.flatMap((group, idx) =>
              [
                (row) => row.cells[idx].is_total,
                (row) => group[row.sort_groups.slice(0,idx + 1).join('-')] == null,
                (row) => group[row.sort_groups.slice(0,idx + 1).join('-')],
                (row) => row.sort_groups[idx],
              ]
            ),
            groupMins.flatMap(() =>
              [
                'desc',
                !this.sort.ascending ? 'asc': 'desc',
                this.sort.ascending ? 'asc': 'desc',
                'asc',
              ]
            )
          )
        } else {
          return this.data.rows
        }
      },
    },
    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },
    mounted () {
      this.loadData()
    },
    beforeDestroy () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component destroyed')
      }
    },
    methods: {
      getRowCell (row, column) {
        return this.data.rows[row][column]
      },
      async loadData () {
        try {
          if (this.currentRequest) {
            this.currentRequest.cancel('New request initiated')
          }

          this.currentRequest = axios.CancelToken.source()
          this.isLoading = true

          const response = await this.$apis.companyConfigs.getDashboardModuleData(
            this.dashboardKey,
            this.dashboardModule.key,
            this.dashboardDataContext,
            { cancelToken: this.currentRequest.token, }
          )
          if (response?.error) {
            if (response?.code !== 'ERR_CANCELED') {
              this.$buefy.toast.open({
                message: 'Failed to load Chart Data',
                type: 'is-warning',
              })
            }
            return
          }
          this.data = response
          this.isLoading = false
          this.sort = {}
        } catch (err) {
          console.error('Error loading data:', err)
        }
      },
    },
  }
</script>

<style scoped>
.table-module {
  border: 1px solid #e9e9ea;
  background-color: white;
}
.table-container {
  display: flex;
  border-top: 1px solid #e9e9ea;
  overflow-x: auto;
  font-size: 12px;
  display: relative;
}
.table-header {
  padding: 12px;
  font-size: 16px;
  color: #333;
  padding: 8px;
  display: flex;
  align-items: center;

  .actions {
    margin-left: auto;

    .mdi-download {
      font-size: 18px;
    }
  }
}
.sticky-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff;
}
</style>
