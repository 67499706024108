<template>
  <CompanyObjectRefSelect
    v-model="vModel"
    :options="options"
    :mode="mode"
    object-type="CompanyDimensionRef"
    placeholder="Select a Time Dimension..."
    external-route="reports_config"
    :external-query="{
      tab: 'companyDimension',
    }" />
</template>

<script>

  import ObjectEditorMixin from '@/components/reports/config/common/ObjectEditorMixin'
  import CompanyObjectRefSelect from '@/components/reports/config/common/CompanyObjectRefSelect'

  export default {
    name: 'TrackerTableTimeDimension',
    components: {
      CompanyObjectRefSelect,
    },
    mixins: [
      ObjectEditorMixin,
    ],
    computed: {
      options () {
        var options = { ...this.companyDimensionOptions, }
        Object.keys(options).forEach(key => {
          if (options[key].dimension.type != 'ExpressionTimeDimension') {
            delete options[key]
          }
        })
        return options
      },
    },
  }
</script>

<style lang="scss" scoped></style>
