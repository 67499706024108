<template>
  <div class="table-column">
    <div
      class="table-column-header"
      :class="{
        'sortable-header': isSortable,
        'dimension-group': column.column_type === 'MEASURE_GROUP',
      }"
      :style="{ height: height + 'px' }"
      @click="toggleSort">
      {{ column.label }}
      <i
        class="sort-icon material-icons"
        :class="{ sorted: isSorted }">
        {{ !isSortAscending ? "arrow_drop_down" : "arrow_drop_up" }}
      </i>
    </div>

    <div
      v-if="hasSubColumns"
      class="table-column-sub-columns">
      <table-column
        v-for="subColumn in column.sub_columns"
        :key="subColumn.id"
        :current-depth="currentDepth + 1"
        :column="subColumn"
        :table-sort="tableSort"
        @update:table-sort="$emit('update:table-sort', $event)" />
    </div>
    <div v-else>
      <TableCell
        v-for="(row, n) in getRows()"
        :key="n"
        :row="row"
        :column="column" />
    </div>
  </div>
</template>

<script>
  import TableCell from './TableCell.vue'

  export default {
    name: 'TableColumn',
    components: {
      TableCell,
    },
    inject: ['getMaxDepth', 'getRows',],
    props: {
      tableSort: {
        type: Object,
        required: true,
      },
      column: {
        type: Object,
        required: true,
      },
      currentDepth: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        headerCellHeight: 40,
      }
    },

    computed: {
      hasSubColumns () {
        return this.column.sub_columns.length > 0
      },

      height () {
        return (
          this.headerCellHeight *
          (this.getMaxDepth() - this.column.depth - this.currentDepth + 1)
        )
      },

      isSortable () {
        return !this.hasSubColumns
      },

      isSorted () {
        return this.isSortable && this.tableSort.rowIdx == this.column.row_idx
      },

      isSortAscending () {
        return this.tableSort.ascending
      },
    },

    mounted () {},
    beforeCreate: function () {
      this.$options.components.tableColumn = require('./TableColumn').default
    },
    methods: {
      toggleSort () {
        if (this.isSortable) {
          if (!this.isSorted) {
            this.$emit('update:table-sort', {
              rowIdx: this.column.row_idx,
              ascending: false,
            })
          } else {
            this.$emit('update:table-sort', {
              rowIdx: this.column.row_idx,
              ascending: !this.tableSort.ascending,
            })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.table-column-header {
  min-width: 200px;
  height: 50px;
  background-color: white;
  border-right: 1px solid #e9e9ea;
  border-bottom: 1px solid #e9e9ea;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.dimension-group {
    text-align: left;
    font-weight: 600;
  }

  .sort-icon {
    font-size: 18px;
    text-align: right;
    margin-left: auto;
    display: none;
    &.sorted{
      display: block !important;
      color: #994095;
    }

  }

}

.table-column-sub-columns {
  display: flex;
}

.sortable-header {
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    .sort-icon {
      display: block !important;
    }
  }
}
</style>
