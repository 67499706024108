var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cellData)?_c('div',{staticClass:"table-cell",class:{
    red: _vm.cellData?.quality === 'bad',
    green: _vm.cellData?.quality === 'good',
    dimension: _vm.column.column_type === 'DIMENSION',
    measure: _vm.column.column_type === 'MEASURE',
    total: _vm.cellData.is_total
  }},[(_vm.cellData.direction && _vm.cellData.direction !== 'none')?_c('i',{staticClass:"material-icons arrow-icon"},[_vm._v(" "+_vm._s(_vm.cellData.direction === "up" ? "arrow_upward" : "arrow_downward")+" ")]):_vm._e(),_c('span',{staticClass:"value"},[_vm._v(" "+_vm._s(_vm.cellData.value)+" ")])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }