<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>
      <div>
        <button
          v-if="drilldownValues?.length"
          class="button clear-button"
          @click="clearDrilldown">
          Clear Drilldown
        </button>
      </div>
    </div>
    <div class="dropdown-filter">
      <singleSelect
        v-model="selectionModel"
        track-by="key"
        :no-border="true"
        label="label"
        :disabled="loading"
        :loading="loading"
        :options="dropdownOptions"
        @input="updateSelectionModel">
        <template #before>
          <div
            v-if="drilldownValues?.length"
            class="drilldown-container">
            <h2>Drilldown</h2>

            <div class="drilldown-items">
              <div
                v-for="(item, index) in drilldownValues"
                :key="index"
                class="drilldown-item">
                <div class="drilldown-content">
                  <div class="drilldown-header">
                    <div class="drilldown-title">
                      {{ optionKeyLabelMap[item.option] }}
                    </div>
                    <button
                      class="remove-drilldown"
                      @click.prevent="removeDrilldown(index)">
                      <i class="material-icons">close</i>
                    </button>
                  </div>

                  <div class="drilldown-value">
                    {{ item.value.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </singleSelect>
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'

  export default {
    name: 'SectionControlFilters',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      drilldownValues () {
        return this.dataContext?.drilldown_path || null
      },
      optionKeyLabelMap () {
        return (
          this.filterMeta.options?.reduce((acc, dv) => {
            acc[dv.key] = dv.label
            return acc
          }, {}) || {}
        )
      },
    },
    watch: {
      drilldownValues: {
        handler (newValue) {
          if (!newValue || newValue.length === 0) {
            this.dropdownOptions = this.filterMeta.options
            this.updateSelectionModel(this.dropdownOptions[0])
          } else {
            // Get array of keys that are in the drilldown path
            const drilldownKeys = newValue.map((dv) => dv.option)
            // Filter options to exclude any that are in drilldown path
            this.dropdownOptions = this.filterMeta.options.filter((option) => {
              return !drilldownKeys.includes(option.key)
            })

            // Update selection if options exist
            if (this.dropdownOptions.length > 0) {
              this.updateSelectionModel(this.dropdownOptions[0])
            }
          }
        },
        immediate: true,
      },
    },
    async mounted () {
      this.dropdownOptions = this.filterMeta.options
      this.updateSelectionModel(this.dropdownOptions[0])
    },

    methods: {
      updateSelectionModel (value) {
        this.selectionModel = value
        this.$set(this.dataContext, 'selected_option', value.key)
      },
      removeDrilldown (index) {
        this.drilldownValues.splice(index, 1)
      },
      clearDrilldown () {
        this.$set(this.dataContext, 'drilldown_path', [])
      },
    },
  }
</script>
<style lang="scss" scoped>
::v-deep {
  .filter-button {
  &:hover {
    background-color: #F5F5F5 !important;
  }
}}

.other-filter {

  border-radius: 4px;
  width: 100%;
  border: 1px solid $gray-1;
}

.drilldown-container {
  padding: 8px 10px;
  background-color: $ui-08;
  border-bottom: 0.3px solid $gray-1;

  h2 {
    margin-bottom: 10px;
  }
}

.dropdown-filter {
  border-radius: 0 0 4px 4px;
}

.drilldown-items {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.drilldown-item {
  background: white;
  border: 1px solid #b654b1;
  border-radius: 4px;
  width: 200px;
  flex: 0 0 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .drilldown-content {
    flex: 1;
  }

  .drilldown-header {
    border-bottom: 1px solid #b654b1;
    background: #994095;
    color: white;
    display: flex;

    .drilldown-title {
      font-size: 12px;
      text-transform: capitalize;
      padding: 4px 8px;
    }

    .remove-drilldown {
      margin-left: auto;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      color: white;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #b654b1;
      padding: 4px 8px;

      i {
        font-size: 16px;
      }

      &:hover {
        background-color: #b654b1;
      }
    }
  }

  .drilldown-value {
    font-size: 14px;
    color: $grayscale-2;
    font-weight: 500;
    padding: 8px;
  }
}

.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.quick-actions {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-1;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;
  border-radius: 4px 4px 0 0;

  .button-label {
    font-size: 13px;
    margin-right: auto;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .clear-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
    background: none;
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: #f0f0f0;
      color: #000;
    }
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
