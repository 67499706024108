import { mapGetters } from 'vuex'
export default {
  methods: {
    async setCompany (companyId) {
      //Todo: rewrite comapny changing logic
      this.$store.commit('expressions/setInitialized', false)
      this.$router.push({ name: 'reports', params: { company_id: companyId, }, })
      await this.$store.dispatch('initialize', companyId)
      await this.$store.dispatch('updateCompanyId', companyId)
    },
  },
  computed: {
    ...mapGetters({
      companyUsers: 'auth/user/companyUsers',
    }),
    navlinks () {
      return {
        reports: {
          name: 'Reports',
          route: { name: 'reports', },
          description:
            'View reports and analyze your data to gain insights about your business',
          keywords: ['reports',],
          showInMobile: true,
        },
        tools: {
          name: 'Tools',
          route: { name: 'tools', },
          description:
            'View your email reports, manage expressions, look up checks, and more',
          children: [
            {
              name: 'Checks',
              route: { name: 'checks', },
              permission: this.$hasPermission('VIEW_CHECKS'),
              description: 'Find and review checks with helpful search tools',
              keywords: [],
            },
            {
              name: 'Checks(New)',
              route: { name: 'checksNew', },
              permission: this.$hasPermission('MANAGE_DASHBOARDS'),
              description: 'Find and review checks with helpful search tools',
              keywords: [],
            },
            {
              name: 'Expression Prototyper',
              route: { name: 'expression_prototyper', },
              permission: this.$hasPermission('PROTOTYPE_EXPRESSIONS'),
              description: 'Develop and test expressions for use in reporting',
              keywords: [],
            },
            {
              name: 'Expressions',
              route: { name: 'expressions', },
              permission: this.$hasPermission('PROTOTYPE_EXPRESSIONS'),
              description: 'Manage and configure expressions',
              keywords: [],
            },

            {
              name: 'Reports Configuration',
              route: { name: 'reports_config', },
              permission: this.$hasPermission('MANAGE_DASHBOARDS'),
              description: 'Manage and configure reports configuration',
              keywords: [],
            },

            {
              name: 'Email Reports',
              route: { name: 'email_report_library', },
              permission: this.$hasPermission('EDIT_EMAIL'),
              description:
                'Manage email reports, recipients, attachments, and manually send emails',
              keywords: [],
            },

            {
              name: 'Report PDFs',
              route: { name: 'pdf_report_library', },
              permission: this.$hasPermission('CONFIGURE_PDF_REPORTS'),
              description:
                'Manage email reports, recipients, attachments, and manually send emails',
              keywords: [],
            },
          ].filter((item) => item.permission),
        },
        data: {
          name: 'Data',
          route: { name: 'data', },
          description:
            'View your integrations, manage data, upload budgets, and more',
          children: [
            {
              name: 'Attributes',
              route: { name: 'attributes', },
              permission: this.$hasPermission('MANAGE_ATTRIBUTES'),
              description: 'Clean up and augment your data with custom tags',
              keywords: [],
            },

            {
              name: 'Upload Forecasts',
              route: { name: 'forecast_uploader', },
              permission: this.$hasPermission('UPLOAD_BUDGETS'),
              description:
                'Upload budgets and forecasts for use within your reporting',
              keywords: [],
            },

            {
              name: 'Integrations',
              route: { name: 'integrations_page', },
              permission: this.$hasPermission('VIEW_INTEGRATIONS'),
              description: 'Manage and view your connected data sources',
              keywords: [],
            },

          ].filter((item) => item.permission),
        },
        user: {
          children: [
            {
              name: 'My Profile',
              route: { name: 'my_profile', },
              permission: true,
              description: '',
              keywords: ['profile',],
            },
          ].filter((item) => item.permission),
        },

        admin: {
          name: 'Admin',
          route: { name: 'admin', },
          description: 'Manage Ingest settings and user permissions',
          children: [
            {
              name: 'Users',
              route: { name: 'users', },
              permission: this.$hasPermission('MANAGE_USERS'),
              description: 'Manage Ingest users',
              keywords: [],
            },

            {
              name: 'Roles',
              route: { name: 'roles', },
              permission: this.$hasPermission('MANAGE_ROLES'),
              description: 'Manage user roles and permissions',
              keywords: [],
            },

            {
              name: 'Company Settings',
              route: { name: 'company_settings', },
              permission: this.$hasPermission('VIEW_COMPANY_SETTINGS'),
              description:
                'View and manage company settings like fiscal calendar, meal periods, and data sources',
              keywords: [],
            },
          ].filter((item) => item.permission),
        },
      }
    },
    allReports () {
      return  [].concat(
        this.$store.getters['auth/user/reports'].map((report) => ({
          key: report.id,
          name: report.name,
          description: report.description,
          icon: report.icon || 'view_list',
          reportID: report.dashboard_id,
          category: 'Reports',
          keywords: ['reports',],
          route: {
            name: 'report',
            params: { dashboard_id: report.dashboard_id, },
          },
          callback: () => {
            //Todo: this is the old way of refreshing reports, change it.
            this.$store.dispatch(
              'dashboards/selectedDashboard/checkStale',
              report.dashboard_id
            )
          },
        })),
        this.$store.getters['app/dashboardReports/dashboardReports'].map(
          dashboardReport => ({
            key: dashboardReport.key,
            name: dashboardReport.name,
            description: dashboardReport.description,
            icon: dashboardReport.icon || 'view_list',
            category: 'Reports',
            keywords: ['reports','dashboards',],
            route: {
              name: 'dashboard',
              params: { id: dashboardReport.key, },
            },
          }))
        )
    },

    allCompanies () {
      if (!this.companyUsers) {
        return []
      }
      const options = this.companyUsers.reduce((accumulator, item) => {
        if (item.company.is_active) {
          accumulator.push({
            ...item.company,
            name: `${item.company.name.replace(/_/g, ' ')} (${
              item.company.id
            })`,
            callback: async () => {
              this.setCompany(item.company.id)
            },
          })
        }
        return accumulator
      }, [])

      const optionsSorted = options.sort((a, b) => {return a.name.localeCompare(b.name)})
      return optionsSorted || []
    },
    hasMultipleCompanies () {
      return this.companyUsers.length > 1
    },
  },
}
