<template>
  <div class="metric">
    <div class="row main-row">
      <div class="left main-title">
        {{ dashboardModule.name }}
      </div>

      <div class="right main-value-container">
        <div
          v-if="!isLoading"
          class="main-value"
          :class="{
            red: data.primary_row.conditional_quality === 'bad',
            green: data.primary_row.conditional_quality === 'good',
          }">
          <i
            v-if="data.primary_row.conditional_direction !== 'none'"
            class="material-icons arrow-icon">
            {{
              data.primary_row.conditional_direction === "up"
                ? "arrow_upward"
                : "arrow_downward"
            }}
          </i>
          {{ data.primary_row?.value }}
        </div>
        <b-skeleton
          v-else
          height="62"
          style="border-radius: 0"
          width="100%"
          :active="isLoading" />
      </div>
    </div>

    <div v-if="!isLoading && data?.secondary_rows">
      <div
        v-for="subRow in data?.secondary_rows"
        :key="subRow.id"
        class="row sub-row">
        <div class="left sub-title">
          {{ subRow.label }}
        </div>

        <div
          class="right sub-value"
          :class="{
            red: subRow.conditional_quality === 'bad',
            green: subRow.conditional_quality === 'good',
          }">
          <i
            v-if="subRow.conditional_direction !== 'none'"
            class="material-icons arrow-icon">
            {{
              subRow.conditional_direction === "up"
                ? "arrow_upward"
                : "arrow_downward"
            }}
          </i>
          {{ subRow.value }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    name: 'KPIModule',
    props: {
      dashboardModule: {
        type: Object,
        required: true,
      },
      dashboardDataContext: {
        type: Object,
        required: true,
      },
      dashboardKey: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        data: null,
        isLoading: true,
        cancelTokenSource: null,
      }
    },
    computed: {},

    watch: {
      dashboardDataContext: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
    },
    mounted () {
      this.loadData()
    },
    beforeDestroy () {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel('Component destroyed')
      }
    },
    methods: {
      async loadData () {
        try {
          // Cancel previous request if it exists
          if (this.cancelTokenSource) {
            this.cancelTokenSource.cancel('New request initiated')
          }

          // Create new CancelToken
          this.cancelTokenSource = axios.CancelToken.source()

          this.isLoading = true

          const response = await this.$apis.companyConfigs.getDashboardModuleData(
            this.dashboardKey,
            this.dashboardModule.key,
            this.dashboardDataContext,
            { cancelToken: this.cancelTokenSource.token, }
          )

          if (response?.error) {
            if (response?.code !== 'ERR_CANCELED') {
              this.$buefy.toast.open({
                message: 'Failed to load KPI Data',
                type: 'is-warning',
              })
            }
            return
          }

          this.data = response
          this.isLoading = false
        } catch (error) {
          console.error('Error loading data:', error)
        }
      },
    },
  }
</script>
<style scoped lang="scss">
.metric {
  background-color: white;
  border: 1px solid #e9e9ea;
  border-radius: 4px;
  overflow: hidden;

  .row {
    display: flex;
    border-bottom: 1px solid #e9e9ea;

    &.main-row{
      color: $ui-01;
    }

    &.sub-row{
      background-color: $ui-08 ;
      color: $ui-03;
    }

    &:last-child {
      border-bottom: none;
    }

    .left {
      padding: 12px 16px;
      border-right: 1px solid #e9e9ea;
      text-align: left;
      width: 55%;
      font-size: 14px;
    }

    .right {
      width: 45%;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .main-title {
      font-size: 16px;
      font-weight: 500;
      height: 62px;
      padding: 16px;
      display: flex;
      align-items: center;
    }

    .main-value-container {
      font-size: 20px;
      font-weight: 500;
      height: 62px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      overflow: hidden;
      .main-value {
        padding: 0 16px;
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;
        align-items: center;
        justify-content: flex-end;
        .arrow-icon {
          font-size: 18px;

          margin-right: auto;
        }
      }
    }

    .sub-value {
      padding: 12px 16px;

      .arrow-icon {
        font-size: 18px;

        margin-right: auto;
      }
    }

    .green {
      color: #008c84;
      &.sub-value {
        background-color: rgba(0, 140, 132, 0.05);
      }
    }
    .red {
      color: #d31b5e;
      &.sub-value {
        background-color: rgba(211, 27, 94, 0.05);
      }
    }
  }
}
</style>
