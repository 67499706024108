<template>
  <div class="">
    <div class="dashboard-header">
      <div class="company-logo">
        <img
          :src="logoUrl"
          alt="" />
      </div>
      <div class="right">
        <h2 class="report-name">
          {{ reportName }}
        </h2>

        <div class="filter-container">
          <restaurant-filter
            v-if="restaurantFilterData"
            class="mr-3 filter"
            :all-controls="filters"
            :control="restaurantFilterData"
            :use-bigquery="betaSpec.use_bq" />
          <time-range-filter
            class="mr-3 filter"
            :default="timeRangeDefault"
            @submit="updateTimeRange" />

          <benchmark-filter
            v-if="comparisonFilterData"
            class="mr-3 filter"
            :control="comparisonFilterData" />

          <other-filters
            v-if="otherFilters.length"
            class="mr-3 filter"
            :all-controls="filters"
            :controls="otherFilters"
            :use-bigquery="betaSpec.use_bq" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TimeRangeFilter from '@/components/controls/filter/TimeRangeFilter.vue'
  import BenchmarkFilter from '@/components/controls/filter/BenchmarkFilter.vue'
  import RestaurantFilter from '@/components/controls/filter/RestaurantFilter.vue'
  import OtherFilters from '@/components/controls/filter/OtherFilters.vue'
  export default {
    name: 'ComponentLibrary',
    components: {
      TimeRangeFilter,
      BenchmarkFilter,
      RestaurantFilter,
      OtherFilters,
    },
    props: {
      brandLogoFname: { type: String, default: null, },
      reportName: { type: String, required: true, },
      filters: { type: Array, required: true, },
      betaSpec: { type: Object, required: true, },
    },
    data () {
      return {
        title: 'Dashboard Header',
        timeRangeFilterDefault: {
          mode: 'calendar',
          calendarPreset: 'last_7',
          fiscalPreset: 'std',
        },
      }
    },
    computed: {
      timeRangeDefault () {
        switch (this.betaSpec.default_dates) {
          case 'today':
            return {
              mode: 'calendar',
              calendarPreset: 'today',
            }
          case 'yesterday':
            return {
              mode: 'calendar',
              calendarPreset: 'yesterday',
            }
          case 'wtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'wtd',
            }
          case 'previous_week':
            return {
              mode: 'fiscal',
              fiscalPreset: 'prev_week',
            }
          case 'last_7_days':
            return {
              mode: 'calendar',
              calendarPreset: 'last_7',
            }
          case 'last_30_days': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_30',
            }
          case 'last_90_days': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_90',
            }
          case 'last_364_days': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_364',
            }
          case 'last_5_weeks':
            return {
              mode: 'calendar',
              calendarPreset: 'last_month',
            }
          case 'last_13_weeks': // New time range filter only
            return {
              mode: 'calendar',
              calendarPreset: 'last_91',
            }
          case 'qtd':
            return {
              mode: 'fiscal',
              fiscalPreset: 'qtd',
            }
          default: // PTD
            return {
              mode: 'fiscal',
              fiscalPreset: 'ptd',
            }
        }
      },
      logoUrl () {
        return `${process.env.VUE_APP_S3_BASE_URL}/${this.brandLogoFname}`
      },

      restaurantFilterData () {
        return this.filters.find((filter) => this.isLocationFilter(filter))
      },

      comparisonFilterData () {
        return this.filters.find((filter) => filter.type === 'date-offset-select')
      },

      otherFilters () {
        const otherFilters = this.filters.filter(
          (filter) => {

            if (this.isLocationFilter(filter) || filter.type === 'date-offset-select') {
              return false
            }

            return filter.type === 'dimension-select'

          }
        ) || []

        return otherFilters
      },
    },
    mounted () {},
    methods: {
      isLocationFilter (filter) {
        return ['Location', 'Restaurant',].includes(filter.name) && !filter.spec.allowMulti
      },

      updateTimeRange (data) {
        if (data.mode === 'RANGE') {
          this.$store.dispatch('datePicker/selectRangeStart', data.dates[0])
          this.$store.dispatch('datePicker/selectRangeEnd', data.dates[1])
        } else {
          this.$store.dispatch('datePicker/selectDates', data.dates)
        }
        this.$store.dispatch('datePicker/apply')
      },
    },
  }
</script>

<style lang="scss" scoped>
.dashboard-header {
  background: $grayscale-9;
  border: $border-1;
  box-shadow:  0px 2px 4px rgba(40, 39, 43, 0.04), 0px 2px 8px rgba(40, 39, 43, 0.02);
  border-top: none;
  padding: 16px 7px 16px 16px;
  display: flex;
  align-items: center;
  border-radius: 0 0 8px 8px;

  .report-name {
    border-radius: 4px;
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: 700;
  }
  .company-logo {
    margin-right: 16px;
    width: 94px;
    height: 94px;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: $border-1;

    .company-img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.right{
  width: 100%;
}
.filter-container {
  display: flex;
  margin-top: 1px;
  background-color: transparent;
  padding: 0;
  background: $grayscale-9;
  border-radius: 4px;
  width: 100%;

  .filter{
    display: flex;
    width: 100%;
    max-width: 300px;
  }
}
</style>
