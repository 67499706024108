<template>
  <header class="mobile-header">
    <div class="top">
      <div class="report-details">
        <h1>{{ dashboardDetails?.name }}</h1>
      </div>

      <button
        class="button is-light filter-button"
        @click="openSidebar">
        <i class="material-icons">filter_alt</i>
      </button>
    </div>
    <div class="bottom">
      <div
        v-if="dashboardDetails?.key"
        class="filter-section">
        <div
          v-for="requiredFilter in dashboardDetails.required_filters"
          :key="requiredFilter.id"
          class="filter">
          <location-filter
            v-if="requiredFilter.key === 'location'"
            v-model="dataContext"
            :dashboard-key="dashboardDetails.key"
            :filter-key="requiredFilter.key" />
        </div>
        <div class="filter">
          <time-range-filter v-model="dataContext" />
        </div>
        <div
          v-for="filter in dashboardDetails.dashboard_controls"
          :key="filter.id"
          class="filter">
          <DashboardControlFilters
            v-model="dataContext"
            :filter-meta="filter" />
        </div>
      </div>
      <b-sidebar
        v-model="isSidebarOpen"
        width="500"
        :fullheight="true"
        :right="true">
        <div class="optional-filters">
          <DashboardOptionalFilters
            v-model="dataContext"
            :optional-filters="dashboardDetails?.optional_filters"
            :dashboard-key="dashboardDetails?.key" />
        </div>
      </b-sidebar>
    </div>
  </header>
</template>

<script>
  import DashboardControlFilters from './filters/DashboardControlFilters.vue'
  import LocationFilter from './filters/LocationFilter.vue'
  import TimeRangeFilter from './filters/DashbaordTimeRangeFilter.vue'
  import DashboardOptionalFilters from './filters/DashboardOptionalFilters.vue'

  export default {
    components: {
      TimeRangeFilter,
      DashboardOptionalFilters,
      LocationFilter,
      DashboardControlFilters,
    },
    props: {
      dashboardDetails: {
        type: Object,
        default: () => {},
      },
      value: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        customFiltersDropdown: false,
        isSidebarOpen: false,
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      appliedOptionalFiltersLabel () {
        const selectedFilters = Object.keys(this.dataContext.filter_selections)
        if (selectedFilters.length === 0) {
          return 'None'
        }
        return this.dashboardDetails.optional_filters
          .filter((item) => selectedFilters.includes(item.key))
          .map((filter) => filter.name)
          .join(', ')
      },
    },
    methods: {
      openSidebar () {
        this.isSidebarOpen = true
      },
    },
  }
</script>

<style lang="scss" scoped>
.mobile-header {
  background-color: white;
  border-bottom: 1px solid #d2d2d4;
}

.filter-button{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 1px solid $gray-1;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  .icon {
    font-size: 16px;
    color: $grayscale-2;
  }

  &:hover {
    background-color: $grayscale-7;
  }

}

.top {
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.report-details h1 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.filter-icon {
  cursor: pointer;
}

.filters {
  padding: 16px;
  background-color: $grayscale-7;
}

.filter-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.bottom {
  padding: 10px;
  background-color: $grayscale-7;
  .filter {
    border: 1px solid #d2d2d4;
  }
}
.filter {
  width: 100%;
  background-color: white;
}

.custom-filters-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.custom-filter-label {
  font-size: 14px;
  color: #333;
}

.icon {
  color: #666;
}

.optional-filters {
  background-color: #f9f9f9;
  border-top: 1px solid #d2d2d4;
}
</style>
