<template>
  <div class="other-filter">
    <div class="quick-actions">
      <div class="button-label">
        {{ filterMeta?.name }}
      </div>
      <div class="secondary-value">
        {{ selectionModel?.label }}
      </div>
    </div>
    <div class="dropdown-filter">
      <singleSelect
        v-model="selectionModel"
        track-by="key"
        :no-border="true"
        label="label"
        :header-label="selectionRangeText"
        :disabled="loading"
        :loading="loading"
        :options="validDropdownOptions"
        @input="updateSelectionModel" />
    </div>
  </div>
</template>

<script>
  import singleSelect from '@/components/ui/singleselect'
  import * as moment from 'moment'

  export default {
    name: 'DashboardBenchmarkFilter',
    components: {
      singleSelect,
    },
    mixins: [],
    props: {
      filterMeta: {
        type: Object,
        required: true,
      },
      value: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        selectionModel: null,
        loading: false,
        dropdownOptions: [],

        durations: {
          previous_day: 1,
          previous_week: 7,
          previous_period: 28,
          previous_quarter: 91,
          previous_year: 364,
          benchmark: 0,
        },
      }
    },
    computed: {
      dataContext: {
        get () {
          return this.value
        },
        set (newValue) {
          this.$emit('input', newValue)
        },
      },
      validDropdownOptions () {
        return this.dropdownOptions.filter((option) =>
          this.isOffsetViable(option.key)
        )
      },
      benchmarkDateRange () {
        const selectedDates = this.dataContext?.date_selection?.selected_dates
        const selectedOption = this.selectionModel?.key
        if (selectedDates.length > 0 && this.selectionModel?.key && this.durations[selectedOption]) {
          const offsetDays = this.durations[selectedOption] || 0

          const startDate = moment(selectedDates[0])
            .subtract(offsetDays, 'days')
            .format('ll')
          const endDate = moment(selectedDates[selectedDates.length - 1])
            .subtract(offsetDays, 'days')
            .format('ll')
          return { startDate, endDate, }
        }
        return { startDate: null, endDate: null, }
      },
      selectionRangeText () {
        if (!this.selectionModel) return ''

        const startDate = this.benchmarkDateRange.startDate
        const endDate = this.benchmarkDateRange.endDate

        if (!startDate) return ''

        return `${startDate} ${
          endDate && endDate != startDate ? ` to ${endDate}` : ''
        }`
      },
    },
    watch: {},
    async mounted () {
      this.dropdownOptions = [...this.filterMeta.options,]
      this.updateSelectionModel(this.validDropdownOptions[0])
      this.selectionModel = this.validDropdownOptions[0]
    },

    methods: {
      isOffsetViable (option) {
        if (option === 'forecast') return true
        const duration = this.durations[option]
        let start = moment(this.dataContext.date_selection.selected_dates[0])
        let end = moment(
          this.dataContext.date_selection.selected_dates[
            this.dataContext.date_selection.selected_dates.length - 1
          ]
        )
        let durationDays = moment(end).diff(moment(start), 'days') + 1
        return duration >= durationDays
      },
      updateSelectionModel (value) {
        this.$set(this.dataContext.control_selections, this.filterMeta.key, {
          type: this.filterMeta.type,
          selected_option: value.key,
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
.other-filter {
  background-color: white;
  border-radius: 4px;
  width: 100%;
}
.filter-button {
  border: 1px solid $gray-1;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;

  &.filtered {
    border: 1px solid #ffb1a0;

    .icon {
      color: #ff6341 !important;
    }

    .button-value {
      color: #ff6341 !important;
    }
  }

  .icon {
    color: $gray-3;
    font-size: 18px !important;

    &.right {
      margin-left: auto;
      margin-right: 0;
    }
  }

  .button-content {
    margin: 0 15px;
    height: 28px;
    display: flex;
    align-items: center;
    .button-value {
      font-size: 14px;
      color: $grayscale-2;
      max-width: 200px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .loading-message {
      font-size: 13px;
      color: $grayscale-4;
    }
  }
  &:hover {
    box-shadow: $box-shadow-1;
  }
}

.dropdown-content {
  border-radius: 4px !important;
}

.secondary-value {
  text-align: left;
  padding: 0 10px;
  font-size: 13px;
  color: #7e7d84;
}

.quick-actions {
  display: flex;
  align-items: center;
  // border: 1px solid $gray-1;
  // border-bottom: none;
  border-bottom: 1px solid $gray-1;
  border-top: none;
  background-color: #fbfbfb;
  height: 30px;
  overflow: hidden;

  .button-label {
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
    padding: 0 15px;
    border-right: 1px solid $gray-1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .quick-action-button {
    height: 100%;
    font-size: 13px;
    color: #504f54;
    font-weight: 200;
  }

  .remove-button {
    border: none;
    color: #504f54;
    background-color: transparent;
    padding: 0;
    width: 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    border-left: 1px solid $gray-1;

    outline: none;

    &:focus {
      outline: none;
    }

    .icon {
      font-size: 17px;
    }
  }
}
</style>
