<template>
  <div class="dashboard-container">
    <nav-bar class="is-hidden-mobile" />
    <mobile-nav-bar class="is-hidden-tablet" />
    <div class="router-container">
      <transition name="fade">
        <router-view
          :key="$route.params.company_id"
          class="router-view" />
      </transition>
    </div>
  </div>
</template>

<script>
  import NavBar from '@/components/navigation/NavBar'
  import MobileNavBar from '@/components/navigation/MobileNavbar'

  export default {
    name: 'DashboardLayout',
    components: {
      NavBar,
      MobileNavBar,
    },
    async mounted () {
      //init google analytics
      this.$ga.set('userId', this.$store.getters['auth/loggedInUser'].id)
      this.$ga.set('dimension1', this.$store.getters['auth/loggedInUser'].id)
    },
  }
</script>
<style lang="scss" scoped>
.dashboard-container {
  height: 100%;
  display: block;
}
.router-container {
  padding-top: 95px;
  background-color: $dashboard-bg;
  height: 100%;
  overflow-y: scroll;

  @include mobile {
    padding-top: 55px;
    }
}
</style>
