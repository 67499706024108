<template>
  <div
    ref="lineChart"
    class="line-chart-container" />
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'LineChart',
    inject: {
      isContained: {
        default: () => true,
      },
    },
    props: {
      data: {
        type: Array,
        required: true,
      },
      xAxisLabels: {
        type: Array,
        required: true,
      },
      yAxisDataType: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
        chartInstance: null,
      }
    },
    computed: {
      isContainerExpanded () {
        return this.isContained()
      },
      chartOptions () {
        return {
          tooltip: {
            trigger: 'axis',
            backgroundColor: '#28272A',
            borderColor: '#333333',
            textStyle: {
              color: '#FFFFFF',
            },
            axisPointer: {
              type: 'line',
              animation: true,
              lineStyle: {
                color: '#6E7079',
                width: 1,
              },
            },
            confine: true,
            formatter: (params) => {
              // Only show one tooltip per date
              const date = params[0].axisValue
              let result = `<div style="text-align:center;font-size:12px;color:#FFFFFF">${date}</div><br/>`
              result += '<div style="display:grid;grid-template-columns:1fr auto 1px 1fr;gap:10px;font-size:11px;color:#FFFFFF">'

              // Sort values from highest to lowest and filter out null values
              const sortedParams = params
                .filter(param => param.value !== null)
                .sort((a, b) => {
                  const aValue = this.data[a.seriesIndex].data[a.dataIndex]
                  const bValue = this.data[b.seriesIndex].data[b.dataIndex]
                  return bValue - aValue
                })

              // Split into two columns
              const midpoint = Math.ceil(sortedParams.length / 2)
              const leftColumn = sortedParams.slice(0, midpoint)
              const rightColumn = sortedParams.slice(midpoint)

              // Build left column
              result += '<div>'
              leftColumn.forEach(param => {
                const value = this.data[param.seriesIndex].formatted_data[param.dataIndex]
                result += `<div style="display:flex;justify-content:space-between;gap:8px">
                  <span>${param.marker}${param.seriesName}:</span>
                  <span style="text-align:right">${value}</span>
                </div>`
              })
              result += '</div>'

              // Add divider
              result += '<div></div><div style="width:1px;background-color:#333333;height:100%"></div>'

              // Build right column
              result += '<div>'
              rightColumn.forEach(param => {
                const value = this.data[param.seriesIndex].formatted_data[param.dataIndex]
                result += `<div style="display:flex;justify-content:space-between;gap:8px">
                  <span>${param.marker}${param.seriesName}:</span>
                  <span style="text-align:right">${value}</span>
                </div>`
              })
              result += '</div>'

              result += '</div>'
              return result
            },
          },
          grid: {
            left: '3%',
            right: '5%',
            bottom: '5%',
            top: '5%',
            containLabel: true,
          },
          xAxis: {
            type: 'category',
            data: this.xAxisLabels,
            boundaryGap: false,
            axisLine: {
              show: true,
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              rotate: 45,
              margin: 15, // Added margin to give more space between axis and labels
              color: '#7E7D84',
            },
            axisPointer: {
              show: true,
              snap: true,
              lineStyle: {
                color: '#6E7079',
                width: 1,
              },
              label: {
                show: true,
                backgroundColor: '#6E7079',
              },
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              margin: 15,
              color: '#B2B1B6',
              formatter: (value) => {
                if (value === 0) return ''

                if (this.yAxisDataType === 'money') {
                  return '$' + (value / 100).toLocaleString()
                }
                if (this.yAxisDataType === 'percentage') {
                  return (value * 100).toFixed(2) + '%'
                }
                return value
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
                color: '#E0E6F1',
              },
            },
          },
          series: this.data.map(line => ({
            name: line.label,
            type: 'line',
            data: line.data,
            lineStyle: {
              width: 2,
              color: line.color,
            },
            itemStyle: {
              color: line.color,
            },
            smooth: false,
          })),
        }
      },
    },
    watch: {
      data: {
        handler (newData) {
          this.$nextTick(() => {
            if (this.chartInstance) {
              const options = this.chartOptions
              options.series = newData.map(line => ({
                name: line.label,
                type: 'line',
                data: line.data,
                lineStyle: {
                  width: 2,
                  color: line.color,
                },
                itemStyle: {
                  color: line.color,
                },
                smooth: false,
              }))
              this.chartInstance.setOption(options, true)
            }
          })
        },
        deep: true,
        immediate: true,
      },
      xAxisLabels: {
        handler (newLabels) {
          this.$nextTick(() => {
            if (this.chartInstance) {
              const options = this.chartOptions
              options.xAxis.data = newLabels
              this.chartInstance.setOption(options, true)
            }
          })
        },
        immediate: true,
      },
      isContainerExpanded: {
        handler () {
          this.$nextTick(() => {
            this.resizeChart()
          })
        },
      },
    },
    mounted () {
      this.createChart()
      window.addEventListener('resize', this.resizeChart)
    },
    beforeUnmount () {
      if (this.chartInstance) {
        this.chartInstance.dispose()
      }
      window.removeEventListener('resize', this.resizeChart)
    },
    methods: {
      createChart () {
        this.chartInstance = echarts.init(this.$refs.lineChart)
        this.chartInstance.setOption(this.chartOptions)
      },
      updateChart () {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.chartOptions, true)
        }
      },
      resizeChart () {
        if (this.chartInstance) {
          this.chartInstance.resize()
        }
      },
    },
  }
</script>

<style>
.line-chart-container {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
